<div [style.display]="showApinavigator ? 'block' : 'none'" @inOutAnimation style="position:absolute" class="card-background">
    <component-cp062 #apiNavigator (selectEvent)="closeApiNavigator($event)" [labels]="labels"></component-cp062>
</div>

<div style="display:flex; justify-content: center;">
    <div *ngIf="context == undefined || loading" class="spinner-div">
        <mat-spinner></mat-spinner>
    </div>
</div>

<mat-tab-group *ngIf="!loading" class="tab-group" color="accent">
    <mat-tab [label]="labels.itemsLabel">
        <div style="display:flex; justify-content:center; height:100%"> 
        <mat-tab-group color="accent" style="width:95%">
            <mat-tab [label]="labels.absencesLabel" *ngIf="context.config.Items.Absences != undefined"> 
                <p-table #dt *ngIf="recordsColumns.length > 0" [rowHover]="false" [rows]="visibleRows" [showCurrentPageReport]="true" styleClass="p-datatable-sm p-datatable-striped" responsiveLayout="scroll" [paginator]="true" [scrollable]="true"
                        [filterDelay]="0" [reorderableColumns]="false" [resizableColumns]="true" [first]="firstRow"
                        [columns]="recordsColumns" [value]="context.config.Items.Absences" sortMode="multiple" [(selection)]="selectedRows">

                        <ng-template pTemplate="caption" id="hideIcons">
                            <div class="table-header" id="hideIcons">
                                <div class="table_buttons">
                                    <button mat-raised-button [disabled]="selectedRows.length > 0" (click)="addRecord(0)" color="accent">
                                        <mat-icon>add_circle</mat-icon>
                                    </button>
                                    <button mat-raised-button [disabled]="selectedRows.length == 0" (click)="removeRecords(0)" color="accent">
                                        <mat-icon>remove_circle</mat-icon>
                                    </button>
                                    <!--
                                    <input type="file" id="uploadFiles" style="display: none" [accept]="allowedFileExtension" (change)="onFileSelected($event)" #fileUpload single>
                                    <button mat-raised-button (click)="fileUpload.click()" matTooltip="Load file" color="accent">
                                        <mat-icon>cloud_upload</mat-icon>
                                    </button>
                                    !-->
                                </div>
                                <div class="search-div">
                                    <span class="p-input-icon-right searchbox-span" id="hideIcons">
                                        <i class="pi pi-search"></i>
                                        <input type="text" pInputText (input)="dt.filterGlobal($any($event.target).value,'contains')" [placeholder]="labels.searchLabel" class="search-input"/>
                                    </span>
                                </div>
                            </div>
                        </ng-template>

                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 3rem">
                                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                </th>
                                <th class="column-header" [pSortableColumn]="recordsColumns[0]" pResizableColumn>
                                    <span class="column-title">
                                        {{recordsColumns[0]}}
                                    </span>
                                </th><th class="column-header" [pSortableColumn]="recordsColumns[1]" pResizableColumn>
                                    <span class="column-title">
                                        {{recordsColumns[1]}}
                                    </span>
                                </th>
                                <!--
                                <th class="column-header" *ngFor="let item of [].constructor(recordsColumns.length); let i = index" [pSortableColumn]="recordsColumns[i]" pResizableColumn>
                                    <span class="column-title">
                                        {{recordsColumns[i]}}
                                    </span>
                                </th>
                                -->
                            </tr>
                        </ng-template>

                        <ng-template pTemplate="body" let-rowData>
                                <tr [pSelectableRow]="rowData">
                                    <td style="width:50px !important">
                                        <p-tableCheckbox [value]="rowData" [disabled]="rowData[recordsColumns[0]] == 'Ordinario' || rowData[recordsColumns[0]] == 'Lavorato'"></p-tableCheckbox>
                                    </td>
                                    <td [pEditableColumn]="rowData[recordsColumns[0]]" [pEditableColumnField]="recordsColumns[0]" style="width:230px !important">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <input pInputText type="text" (keydown.space)="$event.stopPropagation()" [(ngModel)]="rowData[recordsColumns[0]]">
                                            </ng-template>                                        
                                            <ng-template pTemplate="output">
                                                {{ rowData[recordsColumns[0]] }}
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                    <td [pEditableColumn]="rowData[recordsColumns[1]]" [pEditableColumnField]="recordsColumns[1]" style="width:230px !important">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <input pInputText type="text" (keydown.space)="$event.stopPropagation()" [(ngModel)]="rowData[recordsColumns[1]]">
                                            </ng-template>                                        
                                            <ng-template pTemplate="output">
                                                {{ rowData[recordsColumns[1]] }}
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                    <td [pEditableColumn]="rowData[recordsColumns[2]]" [pEditableColumnField]="recordsColumns[2]" style="width:230px !important">
                                        <!--
                                        <mat-form-field appearance="fill" floatLabel="never">
                                            <mat-select required [(ngModel)]="rowData[recordsColumns[2]]">
                                                <mat-option *ngFor="let item of context.itemTypes" [value]="item.Key" (click)="rowData.Type = item.Key">{{item.Value}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>-->
                                        
                                        <p-dropdown appendTo="body"
                                            [options]="asItemTypes" 
                                            [(ngModel)]="rowData[recordsColumns[2]]" 
                                            optionValue="Key" dataKey="Key"
                                            optionLabel="Value" 
                                            placeholder="Type" />
                                    </td>
                                </tr>         
                        </ng-template>

                        <ng-template pTemplate="emptymessage">
                            <tr *ngIf="context.config.Items.Absences.length == 0" >
                                <td [attr.colspan]="recordsColumns.length" class="nodata-cell">
                                    No data found.
                                </td>
                            </tr>
                        </ng-template>

                    </p-table>
                
            </mat-tab>
            <mat-tab [label]="labels.bonusproposalsLabel" *ngIf="context.config.Items.Proposals != undefined"> 
                <p-table #dt3 *ngIf="recordsColumns.length > 0" [rowHover]="false" [rows]="visibleRows" [showCurrentPageReport]="true" styleClass="p-datatable-sm p-datatable-striped" responsiveLayout="scroll" [paginator]="true" [scrollable]="true"
                        [filterDelay]="0" [reorderableColumns]="false" [resizableColumns]="true" [first]="firstRow"
                        [columns]="recordsColumns" [value]="context.config.Items.Proposals" sortMode="multiple" [(selection)]="selectedRows3">

                        <ng-template pTemplate="caption" id="hideIcons">
                            <div class="table-header" id="hideIcons">
                                <div class="table_buttons">
                                    <button mat-raised-button [disabled]="selectedRows3.length > 0" (click)="addRecord(2)" color="accent">
                                        <mat-icon>add_circle</mat-icon>
                                    </button>
                                    <button mat-raised-button [disabled]="selectedRows3.length == 0" (click)="removeRecords(2)" color="accent">
                                        <mat-icon>remove_circle</mat-icon>
                                    </button>
                                    <!--
                                    <input type="file" id="uploadFiles" style="display: none" [accept]="allowedFileExtension" (change)="onFileSelected($event)" #fileUpload single>
                                    <button mat-raised-button (click)="fileUpload.click()" matTooltip="Load file" color="accent">
                                        <mat-icon>cloud_upload</mat-icon>
                                    </button>
                                    !-->
                                </div>
                                <div class="search-div">
                                    <span class="p-input-icon-right searchbox-span" id="hideIcons">
                                        <i class="pi pi-search"></i>
                                        <input type="text" pInputText (input)="dt3.filterGlobal($any($event.target).value,'contains')" [placeholder]="labels.searchLabel" class="search-input"/>
                                    </span>
                                </div>
                            </div>
                        </ng-template>

                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 3rem">
                                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                </th>
                                
                                <th class="column-header" [pSortableColumn]="recordsColumns[0]" pResizableColumn>
                                    <span class="column-title">
                                        {{recordsColumns[0]}}
                                    </span>
                                </th><th class="column-header" [pSortableColumn]="recordsColumns[1]" pResizableColumn>
                                    <span class="column-title">
                                        {{recordsColumns[1]}}
                                    </span>
                                </th>
                            </tr>
                        </ng-template>

                        <ng-template pTemplate="body" let-rowData>
                                <tr [pSelectableRow]="rowData">
                                    <td style="width:50px !important">
                                        <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                                    </td>
                                    <td [pEditableColumn]="rowData[recordsColumns[0]]" [pEditableColumnField]="recordsColumns[0]" style="width:230px !important">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <input pInputText type="text" (keydown.space)="$event.stopPropagation()" [(ngModel)]="rowData[recordsColumns[0]]">
                                            </ng-template>                                        
                                            <ng-template pTemplate="output">
                                                {{ rowData[recordsColumns[0]] }}
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                    <td [pEditableColumn]="rowData[recordsColumns[1]]" [pEditableColumnField]="recordsColumns[1]" style="width:230px !important">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <input pInputText type="text" (keydown.space)="$event.stopPropagation()" [(ngModel)]="rowData[recordsColumns[1]]">
                                            </ng-template>                                        
                                            <ng-template pTemplate="output">
                                                {{ rowData[recordsColumns[1]] }}
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                    <td [pEditableColumn]="rowData[recordsColumns[2]]" [pEditableColumnField]="recordsColumns[2]" style="width:230px !important">
                                        <!--
                                        <mat-form-field appearance="fill" floatLabel="never">
                                            <mat-select required [(ngModel)]="rowData[recordsColumns[2]]">
                                                <mat-option *ngFor="let item of context.itemTypes" [value]="item.Key" (click)="rowData.Type = item.Key">{{item.Value}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>-->
                                        
                                        <p-dropdown appendTo="body"
                                            [options]="paItemTypes" 
                                            [(ngModel)]="rowData[recordsColumns[2]]" 
                                            optionValue="Key" dataKey="Key"
                                            optionLabel="Value" 
                                            placeholder="Type" />
                                    </td>
                                </tr>         
                        </ng-template>

                        <ng-template pTemplate="emptymessage">
                            <tr *ngIf="context.config.Items.Proposals.length == 0" >
                                <td [attr.colspan]="recordsColumns.length" class="nodata-cell">
                                    No data found.
                                </td>
                            </tr>
                        </ng-template>

                    </p-table>
                
            </mat-tab>
            <mat-tab [label]="labels.expensesreportsLabel" *ngIf="context.config.Items.ExpensesReports != undefined"> 
                <p-table #dt2 *ngIf="recordsColumns.length > 0 && tableVisible" [rowHover]="false" [rows]="visibleRows" [showCurrentPageReport]="false" styleClass="p-datatable-sm p-datatable-striped"
                     responsiveLayout="scroll" [paginator]="true" [scrollable]="true"
                    [filterDelay]="0" [reorderableColumns]="false" [resizableColumns]="true" [first]="firstRow2"
                    [columns]="recordsColumns" [value]="context.config.Items.ExpensesReports" sortMode="multiple" [(selection)]="selectedRows2">

                    <ng-template pTemplate="caption" id="hideIcons">
                        <div class="table-header" id="hideIcons">
                            <div class="table_buttons">
                                <button mat-raised-button [disabled]="selectedRows2.length > 0" (click)="addRecord(1)" color="accent">
                                    <mat-icon>add_circle</mat-icon>
                                </button>
                                <button mat-raised-button [disabled]="selectedRows2.length == 0" (click)="removeRecords(1)" color="accent">
                                    <mat-icon>remove_circle</mat-icon>
                                </button>
                                <!--
                                <input type="file" id="uploadFiles" style="display: none" [accept]="allowedFileExtension" (change)="onFileSelected($event)" #fileUpload single>
                                <button mat-raised-button (click)="fileUpload.click()" matTooltip="Load file" color="accent">
                                    <mat-icon>cloud_upload</mat-icon>
                                </button>
                                -->
                            </div>
                            <div class="search-div">
                                <span class="p-input-icon-right searchbox-span" id="hideIcons">
                                    <i class="pi pi-search"></i>
                                    <input type="text" pInputText (input)="dt2.filterGlobal($any($event.target).value,'contains')" [placeholder]="labels.searchLabel" class="search-input"/>
                                </span>
                            </div>
                        </div>
                    </ng-template>

                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 3rem">
                                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                            </th>
                            
                            <th class="column-header" [pSortableColumn]="recordsColumns[0]" pResizableColumn>
                                <span class="column-title">
                                    {{recordsColumns[0]}}
                                </span>
                            </th><th class="column-header" [pSortableColumn]="recordsColumns[1]" pResizableColumn>
                                <span class="column-title">
                                    {{recordsColumns[1]}}
                                </span>
                            </th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-rowData>
                            <tr [pSelectableRow]="rowData">
                                <td style="width:50px !important">
                                    <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                                </td>
                                <td [pEditableColumn]="rowData[recordsColumns[0]]" [pEditableColumnField]="recordsColumns[0]" style="width:230px !important">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input pInputText type="text" (keydown.space)="$event.stopPropagation()" [(ngModel)]="rowData[recordsColumns[0]]">
                                        </ng-template>                                        
                                        <ng-template pTemplate="output">
                                            {{ rowData[recordsColumns[0]] }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td [pEditableColumn]="rowData[recordsColumns[1]]" [pEditableColumnField]="recordsColumns[1]" style="width:230px !important">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input pInputText type="text" (keydown.space)="$event.stopPropagation()" [(ngModel)]="rowData[recordsColumns[1]]">
                                        </ng-template>                                        
                                        <ng-template pTemplate="output">
                                            {{ rowData[recordsColumns[1]] }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <!--
                                <td [pEditableColumn]="rowData[recordsColumns2[2]]" [pEditableColumnField]="recordsColumns2[2]" style="width:230px !important">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input pInputText type="text" (keydown.space)="$event.stopPropagation()" [(ngModel)]="rowData[recordsColumns2[2]]">
                                        </ng-template>                                        
                                        <ng-template pTemplate="output">
                                            {{ rowData[recordsColumns2[2]] }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td [pEditableColumn]="rowData[recordsColumns2[3]]" [pEditableColumnField]="recordsColumns2[3]" style="width:230px !important">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input pInputText type="text" (keydown.space)="$event.stopPropagation()" [(ngModel)]="rowData[recordsColumns2[3]]">
                                        </ng-template>                                        
                                        <ng-template pTemplate="output">
                                            {{ rowData[recordsColumns2[3]] }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                -->
                                <td [pEditableColumn]="rowData[recordsColumns2[4]]" [pEditableColumnField]="recordsColumns2[4]" style="width:230px !important">
                                    <!--
                                    <mat-form-field appearance="fill" floatLabel="never">
                                        <mat-select required [(ngModel)]="rowData[recordsColumns[2]]">
                                            <mat-option *ngFor="let item of context.itemTypes" [value]="item.Key" (click)="rowData.Type = item.Key">{{item.Value}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>-->
                                    
                                    <p-dropdown appendTo="body"
                                        [options]="ntItemTypes" 
                                        [(ngModel)]="rowData[recordsColumns2[4]]" 
                                        optionValue="Key" dataKey="Key"
                                        optionLabel="Value" 
                                        placeholder="Type" />
                                </td>
                            </tr>         
                    </ng-template>

                    <ng-template pTemplate="emptymessage">
                        <tr *ngIf="context.config.Items.ExpensesReports.length == 0" >
                            <td [attr.colspan]="recordsColumns.length" class="nodata-cell">
                                No data found.
                            </td>
                        </tr>
                    </ng-template>

                </p-table>
            </mat-tab>
        </mat-tab-group>
        </div>
    </mat-tab>
    
    <!--
    <mat-tab label="Sources">
        <div style="margin-top:8px; margin-bottom:8px; padding:8px !important"> 
            <mat-expansion-panel *ngFor="let source of context.config.Sources" [style.background-color]="source.Nonstandard != undefined ? 'antiquewhite' : 'whitesmoke'">
                <mat-expansion-panel-header class="header-expansionpanel">
                    <mat-panel-title class="title-expansionpanel">
                        <div>
                            <mat-checkbox color="accent" style="margin-left:24px" (change)="source.Enabled = !source.Enabled" (click)="$event.stopPropagation()"
                                [(ngModel)]="source.Enabled">
                            </mat-checkbox>
                            <span style="margin-left:8px;">{{source.Name}}</span>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container *ngIf="source.Parameters.length == 0">No parameters available</ng-container>
                <ng-container *ngIf="source.Parameters.length > 0">

                </ng-container>
            </mat-expansion-panel>
        </div>
    </mat-tab>
    -->
            <!--
            <mat-expansion-panel style="margin-top:8px; margin-bottom:8px; width:400px">
                <mat-expansion-panel-header>
                    <mat-panel-title>Details</mat-panel-title>
                </mat-expansion-panel-header>
                <mat-form-field style="width:100%">
                    <mat-label></mat-label>
                    <textarea matInput cdkTextareaAutosize [value]="recordSourcesString" (change)="setRecordsSources($event)"></textarea>
                </mat-form-field>
                <div *ngIf="!isRecordsSourcesStringGood" style="display:flex; align-items: center; margin-bottom: 16px;">
                    <mat-icon color="warn">error</mat-icon>
                    <span color="warn">There are errors in the dictionary.</span>
                </div>
                <button mat-raised-button color="accent" [disabled]="compareRecordsSources()" (click)="restoreRecordsSources()">Restore</button>                    
            </mat-expansion-panel>
        </div>
    </mat-tab>
        -->

    <mat-tab [label]="labels.preferencesLabel">
        <mat-card @inOutAnimation *ngIf="context.config.Settings.CompaniesDecodings != undefined" style="margin:12px">
            <mat-card-title class="title-card">{{labels.companiesmappingLabel}}</mat-card-title>
            <mat-card-content>
                <span *ngIf="context.config.Settings.CompaniesDecodings.length == 0" style="width:100%; text-align:center; font-style:italic">
                    {{labels.nocompanymappingsLabel}}
                </span>
                <table *ngIf="context.config.Settings.CompaniesDecodings.length > 0" style="width:100%">
                    <tr>
                        <th>Input (Cezanne)</th>
                        <th>Output</th>
                        <th></th>
                    </tr>
                    <tr *ngFor="let element of context.config.Settings.CompaniesDecodings, index as i">
                        <td>                                     
                            <ng-container *ngIf="context.companiesList.length > 0">    
                                <mat-form-field style="width:95%" appearance="fill">                                           
                                    <mat-label>{{labels.companyLabel}}</mat-label>
                                    <mat-select required [(ngModel)]="element.Input">
                                        <mat-option *ngFor="let table of context.companiesList" style="width: 100%;" [value]="table.Code" (click)="element.Input = table.Code">{{table.Code}} - {{table.Name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                            
                            <ng-container *ngIf="context.companiesList.length == 0">                                            
                                <mat-form-field style="width:95%" appearance="fill">   
                                    <mat-label>{{labels.codeLabel}}</mat-label>
                                    <input matInput [(ngModel)]="element.Input">
                                </mat-form-field>
                            </ng-container>
                        </td>
                        <td>
                            <mat-form-field style="width:100%" appearance="fill">
                                <mat-label>{{labels.codeLabel}}</mat-label>
                                <input matInput [(ngModel)]="element.Output">
                            </mat-form-field>
                        </td>
                        <td style="display: flex;
                            align-items: center;
                            max-width: 64px;
                            height: 64px;
                            margin-top: -2px;
                            justify-content: center;">
                            <button mat-mini-fab (click)="removeCompanyDecoding(i)" style="transform:scale(1)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </tr>
                </table>
                
                <div class="addcompanymapping-div">
                    <button *ngIf="(context.companiesList.length > 0 && context.config.Settings.CompaniesDecodings.length < context.companiesList.length) || context.companiesList.length == 0" mat-mini-fab (click)="addCompanyDecoding()">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
                
            </mat-card-content>
        </mat-card>
        <div class="sortandmappings-div">
            <mat-card @inOutAnimation *ngIf="context.config.Extraction.Sorting != undefined" style="margin:12px; margin-right:0px; flex:50%">
                <mat-card-title class="title-card">{{labels.extractionsortLabel}}</mat-card-title>
                <mat-card-content>
                    <div class="sort-div">
                        <span class="innercardtitle-span">{{labels.elementsLabel}}</span>
                        <mat-chip-set>
                            <ng-container *ngFor="let element of context.config.Extraction.Sorting; index as i">
                                <mat-chip (removed)="removeSortField(context.config.Extraction.Sorting, i)">
                                    <b>{{i}})</b> {{element.Label}}
                                    <button matChipRemove>
                                    <mat-icon>cancel</mat-icon>
                                    </button>
                                </mat-chip> 
                            </ng-container>                                      
                            <button mat-mini-fab style="transform:scale(.5)" color="accent" (click)="addSortField()">
                                <mat-icon>add</mat-icon>
                            </button>
                        </mat-chip-set>
                    </div>                        
                </mat-card-content>
            </mat-card>

            <mat-card style="margin:12px!important; flex:50%">
                <mat-card-title class="title-card">{{labels.payrollcodeoriginLabel}}</mat-card-title>
                <mat-card-content>
                    <mat-radio-group [(ngModel)]="context.config.Settings.PayrollcodeSource" (click)="changePayrollcodeSource($event)" style="display:flex; flex-direction: column; gap: 8px">
                        <mat-radio-button [value]=0 class="payrollsource-radio">{{context.config.Settings.PayrollcodeSources[0].Label}}</mat-radio-button>
                        <mat-radio-button [value]=1 class="payrollsource-radio">{{context.config.Settings.PayrollcodeSources[1].Label}}</mat-radio-button>
                        <mat-radio-button [value]=2 class="payrollsource-radio">{{context.config.Settings.PayrollcodeSources[2].Label}}</mat-radio-button>
                        <mat-radio-button [value]=3 class="payrollsource-radio">{{context.config.Settings.PayrollcodeSources[3].Label}}</mat-radio-button>
                    </mat-radio-group>
                </mat-card-content>
            </mat-card>
        </div>
            
        <div class="sortandmappings-div">
            <mat-card style="margin:12px!important; flex:100%">            
                <mat-card-title class="title-card">{{labels.extractionfilterLabel}}</mat-card-title>
                <ng-container *ngIf="isArray(context.config.Extraction.Filter) && filterType < 0">
                    <mat-card-content class="filter-cardcontent">    
                        <span style="font-style:italic">{{labels.filterdescriptionLabel}}</span><br>
                        <component-cp063 [evalObj]="context.config.Extraction.Filter" [apiList]="context.apiList" [labels]="labels" style="width:100%"></component-cp063>
                    </mat-card-content> 
                </ng-container>
                <ng-container *ngIf="isArray(context.config.Extraction.Filter) && filterType >= 0">
                    <mat-card-content>                        
                        <span style="font-style:italic">{{labels.filterdescriptionLabel}}</span><br><br>
                        <mat-radio-group [(ngModel)]="filterType" (change)="changeExtractionFilter($event)" style="display:flex; flex-direction: column; gap:8px">
                            <mat-radio-button [value]=0 class="payrollsource-radio">{{labels.salarylevelfilterLabel}}</mat-radio-button>
                            <mat-radio-button [value]=1 class="payrollsource-radio">
                                {{labels.ccnlfilterLabel}}
                                <mat-form-field appearance="fill" style="margin-bottom:-6px; margin-left:8px" (click)="$event.stopImmediatePropagation();" >
                                    <mat-label>{{labels.valueLabel}}</mat-label>
                                    <input matInput [(ngModel)]="filterFieldValue" (click)="$event.stopImmediatePropagation();" (keydown.Space)="$event.stopImmediatePropagation();">
                                </mat-form-field>
                            </mat-radio-button>
                            <mat-radio-button [value]=2 class="payrollsource-radio"> 
                                <span>
                                    {{labels.salarylevelccnlfilterLabel}}
                                    <mat-form-field appearance="fill" (click)="$event.stopImmediatePropagation();" style="margin-bottom:-6px; margin-left:8px">
                                        <mat-label>{{labels.valueLabel}}</mat-label>
                                        <input matInput [(ngModel)]="filterFieldValue" (click)="$event.stopImmediatePropagation();"  (keydown.Space)="$event.stopImmediatePropagation();">
                                    </mat-form-field>
                                </span>
                            </mat-radio-button>
                            <mat-radio-button [value]=3 class="payrollsource-radio">{{labels.noneLabel}}</mat-radio-button>
                        </mat-radio-group>
                    </mat-card-content> 
                </ng-container>
                <ng-container *ngIf="!isArray(context.config.Extraction.Filter)">
                    <mat-card-content class="filter-cardcontent">                    
                        <div class="filter-div">
                            <span class="innercardtitle-span">Input</span>
                            <mat-chip-set>
                                <ng-container *ngFor="let input of context.config.Extraction.Filter.Input, index as i">
                                    <mat-chip (removed)="removeFilterField(context.config.Extraction.Filter.Input, i)">
                                        <b>{{i}})</b> {{input.Label}}
                                        <button matChipRemove>
                                        <mat-icon>cancel</mat-icon>
                                        </button>
                                    </mat-chip> 
                                </ng-container>
                                <button mat-mini-fab style="transform:scale(.5)" color="accent" (click)="addFilterField()">
                                    <mat-icon>add</mat-icon>
                                </button>
                            </mat-chip-set>
                        </div>
                        <br>
                        <mat-form-field appearance="fill" *ngIf="context.config.Extraction.Filter != undefined" style="width:100%">
                            <mat-label>Eval</mat-label>
                            <input matInput [(ngModel)]="context.config.Extraction.Filter.Eval">
                        </mat-form-field>
                    </mat-card-content> 
                </ng-container>
                <!--
                <div style="display:flex; flex-direction: column">
                    <span><b>Exclude people that have...</b></span>
                    <mat-radio-group [(ngModel)]="filterType" (change)="changeExtractionFilter($event)">
                        <mat-radio-button value="0" class="payrollsource-radio">Salary Level is empty</mat-radio-button><br>
                        <mat-radio-button value="1" class="payrollsource-radio">CCNL = 
                            <mat-form-field>
                                <mat-label>Value</mat-label>
                                <input matInput [(ngModel)]="filterFieldValue" (keydown.Space)="$event.stopImmediatePropagation();">
                            </mat-form-field>
                        </mat-radio-button><br>
                        <mat-radio-button value="2" class="payrollsource-radio">Salary Level is empty and CCNL = 
                            <mat-form-field>
                                <mat-label>Value</mat-label>
                                <input matInput [(ngModel)]="filterFieldValue" (keydown.Space)="$event.stopImmediatePropagation();">
                            </mat-form-field>
                        </mat-radio-button>
                    </mat-radio-group>
                    <br>
                </div>-->
            </mat-card>
            <!--
            <div style="margin:12px!important; flex:50%"></div>
            -->
        </div>
    </mat-tab>
    <!--
    <mat-tab label="Preferences">
        <div style="padding:8px;padding-top:16px; display:flex; flex-wrap:wrap; gap:32px">
            <mat-card style="padding:12px!important">
                <div style="display:flex; flex-direction: column;">
                    <mat-checkbox [(ngModel)]="companyDecodingsEnabled" [disabled]="!companyDecodings" class="companydecodings-checkbox"><b>Company Decodings</b></mat-checkbox>
                    <mat-card @inOutAnimation *ngIf="companyDecodingsEnabled" style="margin-top:8px; max-width:375px;">
                        <mat-card-content>
                            <table *ngIf="companyDecodingsEnabled" mat-table [dataSource]="companyDecodingsTableData">

                                <ng-container matColumnDef="input">
                                    <th mat-header-cell *matHeaderCellDef> Input </th>
                                    <td mat-cell *matCellDef="let element, index as i">
                                        <mat-form-field style="max-width: 100px; margin-right:16px">
                                            <input matInput [(ngModel)]="element.input">
                                        </mat-form-field>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="output">
                                    <th mat-header-cell *matHeaderCellDef> Output </th>
                                    <td mat-cell *matCellDef="let element">
                                        <mat-form-field style="max-width: 100px;">
                                            <input matInput [(ngModel)]="element.output">
                                        </mat-form-field>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="remove">
                                    <th mat-header-cell *matHeaderCellDef></th>
                                    <td mat-cell *matCellDef="let element, index as i">
                                        <button mat-mini-fab (click)="removeDecoding(i)" style="margin-left:24px">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="companyDecodingsDisplayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: companyDecodingsDisplayedColumns;"></tr>
                            </table>
                            
                            <div style="display:flex; justify-content:center; margin-top:8px; padding-bottom:8px">
                                <button mat-mini-fab (click)="addDecoding()">
                                    <mat-icon>add</mat-icon>
                                </button>
                            </div>
                            
                        </mat-card-content>
                    </mat-card>
                    <br><br>
                    
                    <button mat-raised-button (click)="buildConfig()" color="warn">DEBUG BUILD</button><br>
                </div>
            </mat-card>
            <mat-card style="padding:12px!important;height:142px;">
                <div style="display:flex; flex-direction: column">
                    <span><b>Get Payroll Code from...</b></span>
                    <mat-radio-group [(ngModel)]="payrollSource">
                        <mat-radio-button value="payrollcode" class="payrollsource-radio">Employments.PayrollCode</mat-radio-button><br>
                        <mat-radio-button value="employmentid" class="payrollsource-radio">Employments.EmploymentID</mat-radio-button><br>
                        <mat-radio-button value="visadetails" class="payrollsource-radio">VisaDetails.Number</mat-radio-button>
                    </mat-radio-group>
                    <br>
                </div>
            </mat-card>
            <div>
                <mat-card *ngIf="!isEvalValid" style="padding:12px !important">
                    <span><b>Filter expression (exclude when <i>level</i> is...):</b></span><br>
                    <mat-form-field>
                        <mat-label>Eval</mat-label>
                        <input matInput required [(ngModel)]="filterEvalStr">
                    </mat-form-field>
                </mat-card>
                <mat-card @inOutAnimation *ngIf="filterOn != '' && isEvalValid" class="filter-card">
                    <span><b>Exclude if <i>level</i> is...</b></span><br>
                    <ng-container *ngFor="let condition of filterEval; index as i">
                        <mat-card @inOutAnimation class="condition-card">
                            <mat-form-field style="max-width: 110px;">
                                <mat-label>Operator</mat-label>
                                <mat-select [(ngModel)]="condition.equals" required>
                                <mat-option [value]="true">Equals</mat-option>
                                <mat-option [value]="false">Not Equals</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Value</mat-label>
                                <input matInput required [(ngModel)]="condition.value">
                            </mat-form-field>
                            <button mat-mini-fab (click)="removeCondition(i)" class="remove-button">
                                <mat-icon>remove</mat-icon>
                            </button>
                        </mat-card>
                        <div style="display:flex; justify-content: center;">
                        <mat-button-toggle-group *ngIf="condition.and != undefined" [(ngModel)]="condition.and" (change)="onToggleValueChange($event, i)">
                            <mat-button-toggle [value]="true">And</mat-button-toggle>
                            <mat-button-toggle [value]="false">Or</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                    </ng-container>
                    <div style="display:flex; justify-content:center;">
                    <button mat-mini-fab (click)="addCondition()">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
                </mat-card>
                </div>
            </div>
    
    
    </mat-tab>-->
</mat-tab-group>
<div *ngIf="!loading" class="buttons-div">
    <button mat-raised-button color="basic" (click)="close()" style="margin-right:8px">
        <mat-icon style="margin-top:-4px">clear</mat-icon> {{labels.closeLabel}}
    </button>    
    <button mat-raised-button color="accent" (click)="saveConfig()">
        <mat-icon style="margin-top:-4px">save</mat-icon> {{labels.saveLabel}}
    </button>    
</div>