import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';


interface ElementNode {
  name: string;
  id: string;
  children?: ElementNode[];
}

interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  id: string;
  level: number;
}

@Component({
  selector: 'component-cp062',
  templateUrl: './cp062.component.html',
  styleUrls: ['./cp062.component.scss']
})
export class Cp062Component {
  private _transformer = (node: ElementNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      id: node.id
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  apiList:ElementNode[] = []
  selectionType:number = 0 // 0 = return entity, 1 = return entity.field, 2 = return field
  cardVisible:boolean = false;  
  @Output() selectEvent = new EventEmitter<any>()  
  @Input() labels:any = {}

  ngOnInit() {
    if (Object.keys(this.labels).length == 0) {
      console.log("aa")
      this.labels["selectfieldLabel"] = "Select field"
      this.labels["selectentityLabel"] = "Select entity"
    }
  }

  selectElement(element:any) {
    if (this.selectionType == 2) {
      element.id = element.id.split('.')[1]
    }
    this.selectEvent.emit(element);
    this.close()
  }

  open(apiList:ElementNode[], selectionType:number) {
    this.selectionType = selectionType
    this.dataSource.data = apiList;
    this.cardVisible = true
  }

  close() {
    this.cardVisible = false
  }
}
