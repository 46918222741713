import { Component, Input, Output, EventEmitter } from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { ApiService } from 'src/app/services/api.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { StylerService } from 'src/app/services/styler.service';
import { DialogComponent } from '../dialog/dialog.component';
import { SnackbarmanagerService } from 'src/app/services/snackbarmanager.service';
var lodash = require('lodash');
var jp = require('jsonpath');


@Component({
  selector: 'component-cp083',
  templateUrl: './cp083.component.html',
  styleUrls: ['./cp083.component.scss'],
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ opacity: 0 }),
            animate('.2s ease-out', 
                    style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ opacity: 1 }),
            animate('.1s ease-in', 
                    style({ opacity: 0 }))
          ]
        )
      ]
    )
  ],
})
export class Cp083Component {

  @Input() faguid:any
  @Input() fapara:any
  @Input() labels:any
  @Output() closeFormEvent = new EventEmitter<any>();
  context:any
  loading:boolean = true

  contextBody:any = {}
  configuratorSpecs:any = {}
  stepsList:string[] = []
  parametersList:any = {}
  stepsStatus:any = {}
  stepfunctionMode:boolean = true
  saveEnabled:boolean = true
  gettingFunctions:boolean = false
  selectedFunction:number = -1
  selectedFunctionName:string = ''
  noConfiguration:boolean = false

  constructor(private _snackbarManager:SnackbarmanagerService, public _api:ApiService, public _styler:StylerService, public dialog: MatDialog) {}

  ngOnInit() {   
    this.setFAGUID()
  } 

  setFAGUID() {    
    this.fapara = JSON.parse(this.fapara)
    this.noConfiguration = false
    /*
    var i = 0
    this.context.integrationList.every((func:any) => {
      if (func.FAGUID == this.faguid) return false
      else {
        i += 1
        return true
      }
    })
    this.selectedFunction = i*/
    //console.log(this.context.integrationList[i])
    //this.fapara = JSON.parse(this.context.integrationList[i]["FAPARA"])
    if (this.fapara["stepfunctionContext"] != undefined) {
      this.stepfunctionMode = true
      this.contextBody = this.fapara["stepfunctionContext"]
    } else {
      this.stepfunctionMode = false
      this.contextBody = this.fapara["lambdaParametersStatic"]
    }
    this.configuratorSpecs = this.fapara["configuratorSpecs"]
    
    //console.log(this.configuratorSpecs)
    this.stepsList = []
    this.parametersList = []
    if (this.configuratorSpecs != undefined) {
      this.noConfiguration = false
      Object.keys(this.configuratorSpecs).forEach((k:string) => {
        this.stepsList.push(k)
        if (this.stepfunctionMode) {
          if (this.contextBody[k] == undefined) {
            this.stepsStatus[k] = false
          } else {        
            this.stepsStatus[k] = true
          }
        } else {
          this.stepsStatus[k] = true
        }
        //console.log(k)
        //console.log(this.configuratorSpecs[k])
        if (this.configuratorSpecs[k]["parameters"] != undefined) {
          Object.keys(this.configuratorSpecs[k]["parameters"]).forEach((p:string) => {
            var paramKey = k + '.' + p 
            //console.log(paramKey)
            if (this.stepfunctionMode) {
              //console.log(this.contextBody)
              if (this.contextBody[k] != undefined) {
                this.parametersList[paramKey] = jp.query(this.contextBody[k], "$." + p)[0]
              } else {
                this.parametersList[paramKey] = jp.query(this.contextBody["_" + k], "$." + p)[0]
              }
            } else {
              if (this.fapara["lambdaFunction"] == k) {
                this.parametersList[paramKey] = jp.query(this.contextBody, "$." + p)[0]
              } else {
                if ( this.fapara["lambdaSequence"] != undefined && this.fapara["lambdaSequence"].length > 0) {
                  this.fapara["lambdaSequence"].forEach((lambda:any) => {
                    if (lambda["lambdaName"] == k) {
                      this.parametersList[paramKey] = jp.query(lambda["additionalData"]["lambdaParametersStatic"], "$." + p)[0]
                    }
                  })
                }
              }
            }
          })
        }
      })
    } else {
      this.noConfiguration = true
    }
    //console.log(this.parametersList)
    //console.log(this.parametersList)
    //console.log(this.stepsStatus)
    this.loading = false
  }

  checkParametersOnFunction(functionName:string) {
    var ret = false
    Object.keys(this.parametersList).every((key:any) => {
      if (key.includes(functionName) && this.parametersList[key] != undefined) {
        //console.log(functionName + " found in " + key)
        ret = true
        return false
      } else {
        return true
      }
    })
    return ret
  }

  getFunctionsList() {
    this.selectedFunctionName = ''
    this.selectedFunction = -1
    this.gettingFunctions = true
    this.fapara = undefined
    this.noConfiguration = false
    //console.log(event)
    var context_data = {
      "TEGUID": "",
    }
    console.log(context_data)
    this._api.doButtonPostRequest(context_data).subscribe((response) => {      
      if(response) {        
        this._api.printResponse(response)  
        var responseObj = JSON.parse(JSON.stringify(response))
        this.context = responseObj.Sections[0].Components[0].Component.Context
        this.gettingFunctions = false;
        console.log(this.context)
        this.setFAGUID()
      }
    }, (error) => {
      if(error) {
        //console.log(error);         
        this.gettingFunctions = false;
        this._snackbarManager.open("Error!", 'ko');
      }
    });  
  }

  getParameterValue(path:any, step:any) {
    var query = '$["' + step + '"]' + path
    var value = jp.query(this.contextBody, query);
    return value
  }

  save() {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: "Save confirm",
        content: "Would you like to save the changes?",
        accept: "Yes",
        reject: "No"
      },
    });
    if (this.stepfunctionMode) {
      this.fapara["stepfunctionContext"] = this.contextBody
    } else {
      this.fapara["lambdaParametersStatic"] = this.contextBody
      //console.log(this.fapara["lambdaParametersStatic"])
      //console.log(this.fapara["lambdaSequence"])
    }
    //console.log(context_data_tmp)
    dialogRef.afterClosed().subscribe(result => {   
      if (result) {              
        this._api.loading = true;    
        var context_data:any = {
          "FAGUID": this.faguid
        }
        //this.fapara = JSON.stringify(this.fapara)
        if (this.stepfunctionMode) {
          context_data["stepfunctionContext"] = this.fapara["stepfunctionContext"] 
        } else {
          context_data["lambdaParametersStatic"] = this.fapara["lambdaParametersStatic"]
          context_data["lambdaSequence"] = this.fapara["lambdaSequence"]
        }

        this._api.doButtonPostRequest(context_data, "/function-parameters").subscribe((response) => {      
          if(response) {        
            this._api.printResponse(response);    
            var responseObj = JSON.parse(JSON.stringify(response))
            if (responseObj.isSuccess) {
              this._snackbarManager.open("Success!", 'ok');   
            } else {
              this._snackbarManager.open(responseObj.errorMessage, 'ko');
            }
            this._api.loading = false; 
            this.close(true)
          }
        }, (error) => {
          if(error) {
            console.log(error);    
            this._snackbarManager.open(this.context.PopupLabels[5], 'ko');
          }
        });  
        
      }
    });
  }

  updateParametersValues() {
    Object.keys(this.parametersList).forEach((parameterKey:string) => {
      var stepName = parameterKey.split('.')[0]
      if (this.stepfunctionMode) {
        if (this.contextBody[stepName] == undefined) {
          stepName = "_" + stepName
        }
        var path = "['" + stepName + "']" + parameterKey.replace(parameterKey.split('.')[0], '')
      } else {
        var path = parameterKey.replace(parameterKey.split('.')[0], '').slice(1)
      }      
      //var typePath = "$['" + stepName + "'].parameters['" + parameterKey.replace(parameterKey.split('.')[0], '').slice(1) + "'].type"
      //var parameterType = jp.query(this.configuratorSpecs, typePath)[0]
      var value = this.parametersList[parameterKey] 
      if (value != undefined) {
        //console.log("Set " + path)
        if (this.stepfunctionMode || this.fapara["lambdaFunction"] == stepName) {
          lodash.set(this.contextBody, path, value)
        } else {
          this.fapara["lambdaSequence"].forEach((lambda:any) => {
            if (lambda["lambdaName"] == stepName) {
              lambda["additionalData"]["lambdaParametersStatic"][parameterKey.split('.')[1]] = value
            }
          })
        }
      } else {
        //console.log("Unset " + path)
        lodash.unset(this.contextBody, path)
      }
    })    
    //console.log(this.stepsStatus)
    Object.keys(this.stepsStatus).forEach((stepKey:string) => {
      if (this.stepfunctionMode) {
        if ((!this.stepsStatus[stepKey] && this.contextBody[stepKey] != undefined) || (this.stepsStatus[stepKey] && this.contextBody[stepKey] == undefined)) {
          var newKey = stepKey
          if (!this.stepsStatus[stepKey] && this.contextBody[stepKey] != undefined) {
            newKey = '_' + stepKey  
          } else if (this.stepsStatus[stepKey] && this.contextBody[stepKey] == undefined) {
            stepKey = '_' + stepKey
          }
          this.contextBody[newKey] = JSON.parse(JSON.stringify(this.contextBody[stepKey]))
          delete this.contextBody[stepKey]
          
        }
      } else {        
        if (this.fapara["lambdaSequence"] != undefined && this.fapara["lambdaSequence"].length > 0) {
          this.fapara["lambdaSequence"].forEach((lambda:any) => {
            if (lambda["lambdaName"] == stepKey) {
              if (this.stepsStatus[stepKey]) lambda["active"] = true
              else lambda["active"] = false
            }
          })
          //console.log(this.fapara)
        }
      }
    })
    //console.log(this.contextBody)
    this.save()
  }

  propagateParametersValues() {
    //console.log(this.parametersList)
    this.saveEnabled = true;
    Object.keys(this.parametersList).forEach((key:any) => {
        var stepName = key.split('.')[0]
        var parameterKey = key.replace(stepName, '').substring(1)
        var path = "$['" + stepName + "']['parameters']['" + parameterKey + "']"
        var parameterSpecs = jp.query(this.configuratorSpecs, path)[0]
        if (parameterSpecs != undefined && parameterSpecs.readOnly == true && parameterSpecs.value != undefined) {
          var value = undefined
          if (parameterSpecs.value.variables != undefined) {
            var variables = JSON.parse(JSON.stringify(parameterSpecs.value.variables))
            var evalFormula = parameterSpecs.value.eval
            Object.keys(variables).forEach((variableKey:any) => {
              variables[variableKey] = this.parametersList[variables[variableKey]]
              evalFormula = evalFormula.replaceAll(variableKey, variables[variableKey])
            })
            value = eval(evalFormula)
          } else {
            value = parameterSpecs.value
          }
          lodash.set(this.parametersList, key, value)
        }
        if (parameterSpecs != undefined && parameterSpecs.enabled != undefined && parameterSpecs.enabled.eval != undefined) {
          var enabled = true
          if (typeof parameterSpecs.enabled.eval === "boolean") {            
            enabled = parameterSpecs.enabled.eval
          } else {
            var obj = this as object;
            enabled = eval("obj." + parameterSpecs.enabled.eval)
          }
          if (!enabled) {
            lodash.set(this.parametersList, key, undefined)
          }
        }
        if (this.parametersList[key] == undefined || (this.parametersList[key] == "" && typeof this.parametersList[key] !== "boolean")) {
          this.saveEnabled = false
        }
    })
  }

  getDictLength(dict:any) {
    return Object.keys(dict).length
  }

  close(success?:boolean) {
    this.closeFormEvent.emit({
      isSuccess: success,
      fapara : success ? JSON.stringify(this.fapara) : undefined
    });
  }
}
