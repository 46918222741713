import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Cp062Component } from '../cp062/cp062.component';

export interface Element {
  parameter:string,
  operator:string,
  value:string
}

@Component({
  selector: 'component-cp063',
  templateUrl: './cp063.component.html',
  styleUrls: ['./cp063.component.scss']
})
export class Cp063Component {

  evalString:String = ""
  @Input() evalObj:any = {}
  @Input() apiList:any = {}
  @Input() labels:any = {}
  inputIndex:number = 0

  @ViewChild('apiNavigator') apiNavigator: Cp062Component | undefined;
  showApinavigator:boolean = false

  ngOnInit() {
    
  }  

  addCondition() {
    if (this.evalObj.length > 0) {
      this.evalObj.push("AND")
    }
    this.evalObj.push(
      {
        "Parameter": {
          "Element": "",
          "Label": ""
        },
        "Operator": "==",
        "Value": ""
      }
    )
    this.inputIndex = this.evalObj.length - 1
  }

  removeElement(i:number) {
    if (i > 1) {      
      this.evalObj.splice(i - 1, 2)
    } else if (i == 0 && this.evalObj.length > 1) {      
      this.evalObj.splice(i, 2)
    } else {
      this.evalObj.splice(i, 1)
    }
    console.log(this.evalObj)
  }

  openApiNavigator(selectionType:number, inputIndex:number) {    
    this.showApinavigator = true
    this.inputIndex = inputIndex
    this.apiNavigator?.open(this.apiList, selectionType)
  }

  closeApiNavigator(event:any) {
    console.log(event)
    this.evalObj[this.inputIndex].Parameter.Element = event.id
    this.evalObj[this.inputIndex].Parameter.Label = event.name
    this.showApinavigator = false
  }

  changeAndOr(event:any, i:number) {
    this.evalObj[i] = event.value
  }

  isString(element:any): boolean { return typeof element === 'string'; }
  isArray(element:any): boolean { return Array.isArray(element) }
/*
  removeCondition(index:number) {
    if (this.evalObj[index].and != undefined) {
      delete this.evalStringParsed[index].and
    } else {
      delete this.evalStringParsed[index - 1].and
    }
    this.evalStringParsed.splice(index, 1)
  }*/
}
