<mat-card>
    <mat-card-title class="title-card">
        <mat-icon>update</mat-icon>Functions approval queue 
        <button mat-mini-fab (click)="getList()" color="accent" [disabled]="loading"><mat-icon>refresh</mat-icon></button></mat-card-title>
    <mat-card-content style="display:flex; flex-direction:row; flex-wrap:wrap; gap:8px">
        <div *ngIf="loading" class="loader-innerdiv">
            <mat-spinner></mat-spinner>
        </div>
        <ng-container *ngIf="context != undefined && context['functions'] != undefined && context['functions'].length > 0">
            <mat-card *ngFor="let function of context['functions']; index as i" style="width:365px">
                <mat-card-content style="background-color:white; display:flex; flex-direction:column; justify-content:space-between; height:100%">
                    <div>
                        <span style="font-weight: bold; font-size:18px;margin-bottom:5px">{{function.functionName}}</span>
                        <table style="margin-top:16px">
                            <tr>
                                <td style="text-align:right;">Modified:</td>
                                <td>
                                    <b style="margin-left:8px">{{function.lastModifiedStaging.replace("T", ' ').split('.')[0]}}</b>
                                </td>
                            </tr>
                            <tr>
                                <td style="text-align:right">Version:</td>
                                <td>
                                    <b style="margin-left:8px">
                                        {{function.versionStaging}}
                                    </b>                                    
                                    (prod: {{function.versionProduction}})
                                </td>
                            </tr>
                            <tr ng-container *ngIf="function['layersStaging'].length > 0">
                                <td style="text-align:right">Layers:</td>
                                <td>
                                </td>
                            </tr>
                            <ng-container *ngIf="function['layersStaging'].length > 0">
                                <tr *ngFor="let layer of function['layersStaging']">
                                    <td style="text-align:right; font-style:italic"></td>
                                    <td>                                        
                                        <i style="margin-left:8px">{{layer.name}} </i>
                                        <ng-container>
                                            <b>{{searchLayer(layer.name, function["layersStaging"])}} </b>
                                            (prod: {{searchLayer(layer.name, function['layersProduction'])}})
                                        </ng-container>
                                    </td>
                                </tr>
                            </ng-container>
                        </table>
                    </div>
                    <div style="display: flex; flex-direction: column; align-items: flex-end; margin-top:16px">
                        <button mat-raised-button color="accent" (click)="approve(function.functionName, i)">
                            <mat-icon>check</mat-icon> Approve
                        </button>
                    </div>
                </mat-card-content>
            </mat-card>            
        </ng-container>
        <ng-container *ngIf="context['functions'] != undefined && context['functions'].length == 0">
            There are no new changes on the staging environment.
        </ng-container>
    </mat-card-content>
</mat-card>