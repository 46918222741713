import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { ApiService } from 'src/app/services/api.service';
import { ErrorthrowerService } from 'src/app/services/errorthrower.service';
import { SnackbarmanagerService } from 'src/app/services/snackbarmanager.service';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'component-cp064',
  templateUrl: './cp064.component.html',
  styleUrls: ['./cp064.component.scss'],
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ opacity: 0 }),
            animate('.2s ease-out', 
                    style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ opacity: 1 }),
            animate('.1s ease-in', 
                    style({ opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class Cp064Component {
  @Input() context:any = undefined
  loading:boolean = true

  constructor(private _api:ApiService, private _snackbarManager:SnackbarmanagerService, public dialog: MatDialog, private errorThrower:ErrorthrowerService) {}

  ngOnInit() {
    this.getList()
  }

  getList() { 
    this.loading = true                      
    this.context["functions"] = undefined   
    this._api.doButtonPostRequest({}, "/staging-approver").subscribe((response) => {      
      if (response) {        
        this._api.printResponse(response)
        var responseObj = JSON.parse(JSON.stringify(response))        
        console.log(responseObj)
        this.context = responseObj
        this.loading = false
      }
    }, (error) => {
      if (error) {
        console.log(error);           
        this.loading = false
        this.errorThrower.throw(error.message, 0, true, false)
      }
    });  
  }


  searchLayer(name:string, list:any) {
    var version = "-"
    list.forEach((layer:any) => {
      if (layer["name"] == name) {
        version = layer["version"]
        return false
      } else {
        return true
      }
    })
    return version
  }

  sleep(delay:number) {
    return new Promise((resolve:any) => {
      setTimeout(() => {
        resolve();
      }, delay);
    });
  }

  approve(functionName:string, index:number) {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: "Approval confirmation",
        content: "This function's code, configuration and layers will be copied on the environment production, would you like to continue?",
        accept: "Yes",
        reject: "No"
      },
    })
    
    dialogRef.afterClosed().subscribe(result => {
      if (result) {              
        this._api.loading = true
        var event = {
          "functionName": functionName
        }
        this._api.doButtonPostRequest(event, "/queue").subscribe((response) => {      
          if (response) {        
            this._api.printResponse(response);   
            var responseObj = JSON.parse(JSON.stringify(response))        
            console.log(responseObj)   
            this._api.loading = false  
            this.loading = true                      
            this.context["functions"] = undefined
            this._api.navigate("AP004", "TE0000", "MO022", "FA0406");
            /*
            setTimeout(() => {
              this.getList() 
            }, 1000)
            */
            this._snackbarManager.open("Process started!", 'ok')
          }
        }, (error) => {
          if (error) {
            console.log(error)         
            this._api.loading = false
            this._snackbarManager.open(error.message, 'ko')
          }
        })
      }
    })
  }
}
