        <!--
        <div style="display:flex; flex-direction: row; gap:16px; align-items:center">
        <mat-form-field appearance="fill" hideRequiredMarker="true">
            <mat-label>Choose client</mat-label>
            <mat-select required (selectionChange)="getFunctionsList($event)" [disabled]="context.clientsList.length == 0">
                <ng-container *ngFor="let tenant of context['clientsList']; index as t;">
                    <mat-option [value]="tenant.TEGUID">{{tenant.CLDESC}}</mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" hideRequiredMarker="true" style="width:300px">
            <mat-label>Integration</mat-label>
            <mat-select [disabled]="gettingFunctions || context['integrationList'] == undefined || context['integrationList'].length == 0" [(ngModel)]="selectedFunctionName" (selectionChange)="setFAGUID($event)">
                <ng-container *ngFor="let function of context['integrationList']; index as f;">
                    <mat-option [value]="function.FAGUID">{{function.FADESC}}</mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>
    </div>
    <div *ngIf="context.clientsList.length == 0" class="main-div">
        There are no clients available.
    </div>
    <div *ngIf="noConfiguration" class="main-div">
        There is no configuration for this integration.
    </div>!-->
<div *ngIf="!noConfiguration && fapara != undefined" class="main-div">
    <div *ngFor="let step of stepsList; index as s;" class="parameters-card">
        <mat-card>
            <div class="inner-div">
                <div class="fields-div">
                    <div>
                        <mat-checkbox *ngIf="getDictLength(stepsStatus) > 1" color="accent" [checked]="stepsStatus[step]" (change)="stepsStatus[step] = !stepsStatus[step]; propagateParametersValues()" style="margin-left:20px" [disabled]="configuratorSpecs[step]['permanent'] != undefined && configuratorSpecs[step]['permanent'] == true">
                            <b>{{configuratorSpecs[step]['name']}}</b>
                        </mat-checkbox> 
                    </div>       
                    <div class="parameters-div" *ngIf="configuratorSpecs[step]['parameters'] != undefined && getDictLength(configuratorSpecs[step]['parameters']) > 0 && stepsStatus[step]">                            
                        <!--<mat-divider style="margin-bottom:18px"></mat-divider>!-->
                        <ng-container *ngFor="let parameter of configuratorSpecs[step]['parameters'] | keyvalue; index as s;">
                            <mat-form-field [@inOutAnimation] appearance="fill" hideRequiredMarker="true" [matTooltip]="configuratorSpecs[step]['parameters'][$any(parameter.key)]['description']" matTooltipShowDelay="600" *ngIf="stepsStatus[step] && configuratorSpecs[step]['parameters'][$any(parameter.key)]['visible'] && configuratorSpecs[step]['parameters'][$any(parameter.key)]['type'] == 'number'">
                                <mat-label>
                                    {{configuratorSpecs[step]['parameters'][$any(parameter.key)]['name']}}
                                    <mat-icon *ngIf="configuratorSpecs[step]['parameters'][$any(parameter.key)]['description']" style="margin-bottom:-2px">help_outline</mat-icon>
                                </mat-label>
                                <input matInput required type="number" [(ngModel)]="parametersList[step + '.' +  parameter.key]" [min]="configuratorSpecs[step]['parameters'][$any(parameter.key)]['minValue']" [max]="configuratorSpecs[step]['parameters'][$any(parameter.key)]['maxValue']" (change)="propagateParametersValues()" [disabled]="configuratorSpecs[step]['parameters'][$any(parameter.key)]['readOnly']">
                            </mat-form-field>
                            <mat-form-field [@inOutAnimation] appearance="fill" hideRequiredMarker="true" [matTooltip]="configuratorSpecs[step]['parameters'][$any(parameter.key)]['description']" matTooltipShowDelay="600" *ngIf="parametersList[step + '.' + parameter.key] != undefined && stepsStatus[step] && configuratorSpecs[step]['parameters'][$any(parameter.key)]['visible'] && parametersList[step + '.' + parameter.key] != undefined && stepsStatus[step] && configuratorSpecs[step]['parameters'][$any(parameter.key)]['type'] == 'string'">
                                <mat-label>
                                    {{configuratorSpecs[step]['parameters'][$any(parameter.key)]['name']}}
                                    <mat-icon *ngIf="configuratorSpecs[step]['parameters'][$any(parameter.key)]['description']" style="margin-bottom:-2px">help_outline</mat-icon>
                                </mat-label>
                                <input matInput required type="text" [(ngModel)]="parametersList[step + '.' +  parameter.key]" (change)="propagateParametersValues()" [disabled]="configuratorSpecs[step]['parameters'][$any(parameter.key)]['readOnly']">
                            </mat-form-field>
                            <mat-checkbox [@inOutAnimation] appearance="fill" [matTooltip]="configuratorSpecs[step]['parameters'][$any(parameter.key)]['description']" matTooltipShowDelay="600" 
                            [(ngModel)]="parametersList[step + '.' +  parameter.key]" (change)="propagateParametersValues()" [disabled]="configuratorSpecs[step]['parameters'][$any(parameter.key)]['readOnly']" *ngIf="parametersList[step + '.' + parameter.key] != undefined && stepsStatus[step] && configuratorSpecs[step]['parameters'][$any(parameter.key)]['visible'] && parametersList[step + '.' + parameter.key] != undefined && stepsStatus[step] && (configuratorSpecs[step]['parameters'][$any(parameter.key)]['type'] == 'bool' || configuratorSpecs[step]['parameters'][$any(parameter.key)]['type'] == 'boolean')">
                                    {{configuratorSpecs[step]['parameters'][$any(parameter.key)]['name']}}
                            </mat-checkbox> 
                            <mat-form-field [@inOutAnimation] appearance="fill" hideRequiredMarker="true" [matTooltip]="configuratorSpecs[step]['parameters'][$any(parameter.key)]['description']" matTooltipShowDelay="600" *ngIf="parametersList[step + '.' + parameter.key] != undefined && stepsStatus[step] && configuratorSpecs[step]['parameters'][$any(parameter.key)]['visible'] && parametersList[step + '.' + parameter.key] != undefined && stepsStatus[step] && configuratorSpecs[step]['parameters'][$any(parameter.key)]['type'] == 'combobox'">
                                <mat-label>
                                    {{configuratorSpecs[step]['parameters'][$any(parameter.key)]['name']}}
                                    <mat-icon *ngIf="configuratorSpecs[step]['parameters'][$any(parameter.key)]['description']" style="margin-bottom:-2px">help_outline</mat-icon>
                                </mat-label>
                                <mat-select [(ngModel)]="parametersList[step + '.' +  parameter.key]" required (selectionChange)="propagateParametersValues()" [disabled]="configuratorSpecs[step]['parameters'][$any(parameter.key)]['readOnly']">
                                    <ng-container *ngFor="let option of configuratorSpecs[step]['parameters'][$any(parameter.key)]['choices'] | keyvalue; index as o;">
                                        <mat-option [value]="option.key">{{option.value}}</mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>
                    </div>
                </div>
                <!--
                <mat-icon *ngIf="s < stepsList.length - 1" [style.margin-left]="s < stepsList.length - 1 ? '-10px' : '-20px'">arrow_right</mat-icon>-->
            </div>
        </mat-card>
        <div *ngIf="s < stepsList.length - 1">
            <mat-icon>arrow_right</mat-icon>
        </div>
    </div>
</div>
<div class="buttons-div">
    <button mat-raised-button color="basic" (click)="close(false)" style="margin-right:8px">
        <mat-icon style="margin-top:-4px">clear</mat-icon> {{labels.closeLabel}}
    </button>    
    <button mat-raised-button color="accent" (click)="updateParametersValues()" [disabled]="fapara == undefined || noConfiguration" style="margin-right:4px">
        <mat-icon style="margin-top:-4px">save</mat-icon> {{labels.saveLabel}}
    </button>
</div>